import React, { useEffect, useState } from "react";
import InputContainer from "../Signup/InputContainer/InputContainer";
import SignupButtonContainer from "../Signup/SignupButtonContainer/SignupButtonContainer";
import "./index.scss";
import { useSignUpFlowContext } from "../SignUpFlow/GlobalStates";
import {
  sendOTP,
  validateOTP,
} from "../SignUpFlow/API_reqs";
import SignupNavbar from "../SignUpFlow/Components/SignupNavbar/SignupNavbar";
import axios from "axios";
import config from "../../../config";

const PaymentCheck = (props) => {
  useEffect(() => {});
  const {
    email,
    setEmail,
    headerHeight,
    mode
  } = useSignUpFlowContext();

  const [emailError, setemailError] = useState(false);
  const [emailErrorText, setemailErrorText] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [showInvoices, setShowInvoices] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpDone, setOtpDone] = useState(false);
  const [timer, setTimer] = useState(0);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState("");

  const handleOTP = () => {
    // getInvoices();
    if(!showOtp){
      sendOTP({ email: email })
      .then(async (response) => {
        if (response.status === 201) {
          setOtpSent(true);
          localStorage.setItem("otp", Date.now() + 90000);
          setTimer(90000);
        }
      })
      .catch((error) => {
        setemailError(true);
        setemailErrorText("OTP Sending error. Please try again.");
      });
    }
  };

  const handleOTPVerify = () => {
    validateOTP({
      email: email,
      otp: otp,
    })
      .then(async (response) => {
        if (response.status === 200) {
          setOtpError(false);
          setemailError(false);
          setOtpDone(true);
          getInvoices();
        } else if (response.status === 401) {
          setOtpErrorText("Invalid OTP. Please try again.");
          setOtpError(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOtpErrorText("Invalid OTP. Please try again.");
        } else if (error.response.status === 403) {
          setOtpErrorText("OTP Expired. Please request another OTP");
        }
        setOtpError(true);
      });
  };

  const getInvoices = () => {
    let data ={
      email: email
    }
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      // baseURL: "http://localhost:4000",
      baseURL: config.apiEndpoints.protocol + config.apiEndpoints.authServer,
      url: "/invoice/getall",
      data: data,
    })
    .then((response) => {
      setShowInvoices(true);
      setInvoices(response.data.invoices);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      if (timer > 0) setTimer(timer - 1000);
    }, 1000);
  }, [timer]);

  return (
    <>
      <div className={`SignupFlow-${mode}`}>
        <header className="header-container">
          <SignupNavbar />
        </header>
      </div>
      <div
        className={`main-body-pay-${mode}`}
        style={{
          height: `calc(100vh - ${headerHeight}px)`,
        }}
      >
        <center className="inputs-wrapper">
          <h2>Submit your details</h2>

          <InputContainer
                    color={mode === "darkmode" ? "white" : "#000"}

            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="Email"
            value={email}
            type="text"
            display={otpDone ? "none" : "flex"}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            error={emailError}
            errorText={emailErrorText}
          />

          <div className="pay-sub-text">
            {!otpSent ? (
              <SignupButtonContainer
                title="Send OTP"
                line
                onClick={handleOTP}
              />
            ) : timer > 0 ? (
              "Time Left: " + Math.floor(timer / 1000) + "s"
            ) : (
              <SignupButtonContainer
                title="Resend OTP"
                line
                onClick={handleOTP}
              />
            )}
          </div>

          <div style={{ display: otpSent ? "flex" : "none" }}>
            <InputContainer
                  color={mode === "darkmode" ? "white" : "#000"}

              height="4.18rem"
              fontSize="1rem"
              marginTop="1.088rem"
              placeholder="OTP"
              value={otp}
              type="text"
              display="flex"
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              error={otpError}
              errorText={otpErrorText}
            />
            <div className="pay-verify">
              <SignupButtonContainer
                title="Verify OTP"
                line
                onClick={handleOTPVerify}
              />
            </div>
          </div>

          {otpDone ? "OTP verified" : ""}

          {showInvoices ? (
            <div className="invoices"
            style={{
              'height': '400px',
              'overflow-y': 'scroll',
              'marginTop': '40px'
              }}>
              {invoices.map((invoice) => {
                return (
                  <div className="invoice"
                  style={{
                    'display': 'flex',
                    'justify-content': 'space-between',
                    'margin': '10px 0px'
                  }}>
                    <div className="invoice-title">{new Date(invoice.timestamp.$date).toLocaleDateString()} {new Date(invoice.timestamp.$date).toLocaleTimeString()}</div>
                    <div className="invoice-date">{invoice.items}</div>
                    <div className="invoice-date">{invoice.currency} {invoice.amount}</div>
                    {invoice.status ?
                      <a href={("/payment/success?order_id=" + invoice._id.$oid)}>View Details</a>
                      : <div className="invoice-date">Payment failed</div>
                      }
                  </div>
                );
              })}
            </div>)
            : ""}
        </center>
      </div>
    </>
  );
};

export default PaymentCheck;
