import React, { useState } from "react";
// import { useSignUpFlowContext } from "../../../GlobalStates";
import "../../../SignUpFlow.scss";

const ScheduleMeeting = (props) => {
  // const { footerHeight,headerHeight,pageWidth } = useSignUpFlowContext();
  const [pageWidth, setPageWidth] = React.useState(window.innerWidth);

  useState(() => {
    window.addEventListener("resize", () => {
      setPageWidth(window.innerWidth);
    });
  });

  return (
    <div className="business-details-wrapper"style={{
      width: pageWidth,
    }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: pageWidth > 770 ? "center" : "stretch",
          width: pageWidth,
          // paddingBottom: `${footerHeight}px`,
          // paddingTop: `${headerHeight}px`,
          overflowX: "auto",
        }}
      >
        <h2 className="your-details-header">
          Schedule a meeting with our Tech Team
        </h2>
        <div
          style={{
            // margin: "0 0 1.68rem",
            color: "white",
            fontStyle: "italic",
            fontWeight: "200",
            // width: "auto",
            height: "40rem",
            overflowX: "auto",
          }}
        >
          <iframe
          style={{
            width: '53rem',
            height: '42rem',
            // overflowX: "auto",
          }}
            title="Schedule a meeting with our Tech Team"
            width="100%"
            height="100%"
            src="https://meetings.hubspot.com/dilki?embed=true"
            allowFullScreen
            scrolling="no"
            frameBorder={0}
          ></iframe>
          {/* <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/nipuna-munasinghe?embed=true"></div>
    <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script> */}
        </div>
      </div>
    </div>
  );
};
export default ScheduleMeeting;
