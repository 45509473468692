import React, { useEffect } from "react";
import SignupButtonContainer from "../../../../../../Components/Button/SignupButtonContainer/SignupButtonContainer";
import "../../../SignUpFlow.scss";
import { useSignUpFlowContext } from "../../../GlobalStates";

const InitialPage = (props) => {
  const { setSelectedPage, pageWidth, footerHeight, dnsRecords,mode } =
    useSignUpFlowContext();

  useEffect(() => {
    console.log("InitialPage", dnsRecords);
  });
  return (
    <div
      className="business-details-wrapper"
      style={{
        paddingBottom: `${footerHeight}px`,
        flexDirection: "column",
      }}
    >
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      > */}
      {footerHeight > 0
        && dnsRecords.length !== 0 ? (
            <>
              <h2 className="your-details-header">Update DNS</h2>
              <div
                style={{
                  margin: "0 0 1.68rem",
                  color: mode === "darkmode" ? "white" : "#000",
                  fontStyle: "italic",
                  fontWeight: "200",
                }}
              >
                <p>
                  You can get help our zapmail tech team or you can update DNS
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                <SignupButtonContainer
                  width={pageWidth < 500 ? "9.875rem" : "15.875rem"}
                  title="View DNS Records"
                  grey
                  onClick={() => {
                    setSelectedPage(8);
                  }}
                />
                <SignupButtonContainer
                  width={pageWidth < 500 ? "9.875rem" : "15.875rem"}
                  title="Get Help"
                  grey
                  onClick={() => {
                    setSelectedPage(7);
                  }}
                />
              </div>
            </>
          )
        : <font color="white">The domain ID is not valid</font>}
    </div>
    // </div>
  );
};
export default InitialPage;
