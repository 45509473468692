import React, { useEffect } from "react";
import "../../../SignUpFlow.scss";
import Done from "../../../../../../Assets/Icons/done.png";
import Cancel from "../../../../../../Assets/Icons/redCross.png";
import Warning from "../../../../../../Assets/Icons/warning.png";
import check from "../../../../../../Assets/Icons/check.png";
import Modal from "../../../Components/Modal/Modal";
// import { useParams } from "react-router-dom";
// import { getDNS } from "../../../API_reqs";
import { useSignUpFlowContext } from "../../../GlobalStates";

const ViewDNS = (props) => {
  const {
    selectDomain,
    dnsRecords,
    recordStates,
    dkim1Error,
    dkim2Error,
    mailCnameError,
    mxRecordError,
    dkim1ErrorText,
    dkim2ErrorText,
    mailCnameErrorText,
    mxRecordErrorText,
    mxErrorAgree,
    setMxErrorAgree,
    recordsDone,
    setRecordsDone,
    footerHeight,
    headerHeight,
    pageWidth,
    mode
  } = useSignUpFlowContext();

  const [copied, setCopied] = React.useState(false);
  const [copied1, setCopied1] = React.useState(false);
  const [copied2, setCopied2] = React.useState(false);
  const [copied3, setCopied3] = React.useState(false);
  const [copied4, setCopied4] = React.useState(false);
  const [copied5, setCopied5] = React.useState(false);
  const [copied6, setCopied6] = React.useState(false);
  const [copied7, setCopied7] = React.useState(false);
  const [copied8, setCopied8] = React.useState(false);

  useEffect(() => {
    console.log(dnsRecords);
    console.log("DNS Records Updated");
  }, []);

  return (
    <div
      className="business-details-wrapper"
      style={{
        paddingBottom: `${footerHeight}px`,
        paddingTop: 1400 > pageWidth && `${headerHeight + 100}px`,
        flexDirection: "column",
      }}
    >
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      > */}
      <h2 className="your-details-header">Update DNS Records</h2>
      {/* <div

          style={{
            // margin: "0 0 1.68rem",
            fontWeight: "200",
            // minWidth: "66rem",
            height: "39.3rem",
          }}
        > */}
      <div className={`ViewDNS-${mode}`}>
        <div className="domain">Domain - {selectDomain}</div>
        <div
          style={{
            fontStyle: "italic",
            marginBottom: "2.5rem",
            fontWeight: "200",
          }}
        >
          {" "}
          Copy these records into your DNS provider and hit I'm done.
          <br />
          (*records can take several hours to update.)
        </div>
        <div
          style={{
            overflowX: "auto",
          }}
        >
          <table style={{ minWidth: "66rem" }}>
            <thead>
              <tr>
                <td>Type</td>
                <td>Name</td>
                <td>Priority</td>
                <td>Value</td>
              </tr>
            </thead>
            <tbody>
              {
                /* {dnsRecords.map((record, index) => {
                    return ( */
                // console.log(recordStates)
              }
              <tr key={1}>
                <td className="top-border">
                  {dnsRecords?.cname_records.dkim1.type.toUpperCase()}
                </td>
                <td
                  className="top-border"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      dnsRecords?.cname_records.dkim1.host
                    );
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 800);
                  }}
                >
                  {copied ? (
                    <center>Copied</center>
                  ) : (
                    dnsRecords?.cname_records.dkim1.host
                  )}
                </td>
                <td className="top-border">N/A</td>
                <td
                  className="top-border"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      dnsRecords?.cname_records.dkim1.data
                    );
                    setCopied1(true);
                    setTimeout(() => {
                      setCopied1(false);
                    }, 800);
                  }}
                >
                  {copied1 ? (
                    "Copied"
                  ) : (
                    dnsRecords?.cname_records.dkim1.data
                  )}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    visibility:
                      recordStates.length === 0 ? "hidden" : "visible",
                  }}
                >
                  <img src={dkim1Error ? Cancel : Done} alt="Done" />
                </td>
              </tr>

              <tr style={{ display: dkim1Error ? "" : "none" }}>
                <td colSpan={4} className="dnsError">
                  {dkim1ErrorText}
                </td>
              </tr>
              <tr key={2}>
                <td className="top-border">
                  {dnsRecords?.cname_records.dkim2.type.toUpperCase()}
                </td>
                <td
                  className="top-border"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      dnsRecords?.cname_records.dkim2.host
                    );
                    setCopied2(true);
                    setTimeout(() => {
                      setCopied2(false);
                    }, 800);
                  }}
                >
                  {copied2 ? (
                    <center>Copied</center>
                  ) : (
                    dnsRecords?.cname_records.dkim2.host
                  )}
                </td>
                <td className="top-border">N/A</td>
                <td
                  className="top-border"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      dnsRecords?.cname_records.dkim2.data
                    );
                    setCopied3(true);
                    setTimeout(() => {
                      setCopied3(false);
                    }, 800);
                  }}
                >
                  {copied3 ? (
                    "Copied"
                  ) : (
                    dnsRecords?.cname_records.dkim2.data
                  )}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    visibility:
                      recordStates.length === 0 ? "hidden" : "visible",
                  }}
                >
                  <img src={dkim2Error ? Cancel : Done} alt="Done" />
                </td>
              </tr>

              <tr style={{ display: dkim2Error ? "" : "none" }}>
                <td colSpan={4} className="dnsError">
                  {dkim2ErrorText}
                </td>
              </tr>
              <tr key={3}>
                <td className="top-border">
                  {dnsRecords?.cname_records.mail_cname.type.toUpperCase()}
                </td>
                <td
                  className="top-border"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      dnsRecords?.cname_records.mail_cname.host
                    );
                    setCopied4(true);
                    setTimeout(() => {
                      setCopied4(false);
                    }, 800);
                  }}
                >
                  {copied4 ? (
                    <center>Copied</center>
                  ) : (
                    dnsRecords?.cname_records.mail_cname.host
                  )}
                </td>
                <td className="top-border">N/A</td>
                <td
                  className="top-border"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      dnsRecords?.cname_records.mail_cname.data
                    );
                    setCopied5(true);
                    setTimeout(() => {
                      setCopied5(false);
                    }, 800);
                  }}
                >
                  {copied5 ? (
                    "Copied"
                  ) : (
                    dnsRecords?.cname_records.mail_cname.data
                  )}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    visibility:
                      recordStates.length === 0 ? "hidden" : "visible",
                  }}
                >
                  <img src={mailCnameError ? Cancel : Done} alt="Done" />
                </td>
              </tr>

              <tr style={{ display: mailCnameError ? "" : "none" }}>
                <td colSpan={4} className="dnsError">
                  {mailCnameErrorText}
                </td>
              </tr>
              <tr key={4}>
                <td className="top-border">
                  {dnsRecords?.mx_record.type.toUpperCase()}
                </td>
                <td
                  className="top-border"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(dnsRecords?.mx_record.host);
                    setCopied6(true);
                    setTimeout(() => {
                      setCopied6(false);
                    }, 800);
                  }}
                >
                  {copied6 ? (
                    <center>Copied</center>
                  ) : (
                    dnsRecords?.mx_record.host
                  )}
                </td>
                <td
                  className="top-border"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      dnsRecords?.mx_record.priority
                    );
                    setCopied7(true);
                    setTimeout(() => {
                      setCopied7(false);
                    }, 800);
                  }}
                >
                  {copied7 ? (
                    "Copied"
                  ) : (
                    dnsRecords?.mx_record.priority
                  )}
                </td>
                <td
                  className="top-border"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(dnsRecords?.mx_record.data);
                    setCopied8(true);
                    setTimeout(() => {
                      setCopied8(false);
                    }, 800);
                  }}
                >
                  {copied8 ? (
                    "Copied"
                  ) : (
                    dnsRecords?.mx_record.data
                  )}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    visibility:
                      recordStates.length === 0 ? "hidden" : "visible",
                  }}
                >
                  <img
                    src={
                      mxRecordError === "warning"
                        ? Warning
                        : mxRecordError === true
                        ? Cancel
                        : Done
                    }
                    alt="Done"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={4} className="bottom-border dnsError">
                  <div
                    style={{
                      display:
                        mxRecordError || mxRecordError === "warning"
                          ? ""
                          : "none",
                    }}
                  >
                    {mxRecordErrorText}
                  </div>
                </td>
              </tr>
              {/* );
                  })} */}
            </tbody>
          </table>
        </div>
        <div style={{ display: mxRecordError === "warning" ? "flex" : "none" }}>
          <div
            style={{
              marginBottom: "1.25rem",
              marginTop: "0rem",
            }}
            className={`check-container`}
            onClick={() => {
              setMxErrorAgree(!mxErrorAgree);
              if (!dkim1Error && !dkim2Error && !mailCnameError) {
                setRecordsDone(!recordsDone);
              }
              // setRecordsDone(!recordsDone);
            }}
          >
            <div className={`check-box ${mxErrorAgree ? "checked" : ""}`}>
              {mxErrorAgree && <img alt="" src={check} className="check" />}
            </div>
            Create the account while keeping the MX records as it is. If you
            have any issues, please contact our support team.
          </div>
        </div>
        <div style={{ fontWeight: "200", fontStyle: "italic" }}>
          *How to update DNS records.{" "}
          <u className="domain" style={{ fontStyle: "italic" }}>
            <a href="/Files/DNS_Updating Guidebook_compressed.pdf">Click Here</a>
          </u>
        </div>
      </div>

      {/* <div class="meetings-iframe-container" data-src="https://meetings.hubspot.com/nipuna-munasinghe?embed=true"></div>
    <script type="text/javascript" src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script> */}
    </div>
    // </div>
    // </div>
  );
};

export default ViewDNS;
