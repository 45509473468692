import React, { useEffect, useState } from "react";
import InputContainer from "../Signup/InputContainer/InputContainer";
import SignupButtonContainer from "../Signup/SignupButtonContainer/SignupButtonContainer";
import "./index.scss";
import "../SignUpFlow/SignUpFlow.scss";
import { useSignUpFlowContext } from "../SignUpFlow/GlobalStates";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Payhere,
  AccountCategory,
  PayhereSubscription,
  SubscriptionParams,
  Customer,
  Month,
  CurrencyType,
} from "@payhere-js-sdk/client";
import axios from "axios";
import SignupNavbar from "../SignUpFlow/Components/SignupNavbar/SignupNavbar";
import check from "../../../Assets/Icons/check.png";
import config from "../../../config";
import { prePaymentUserInfo } from "../SignUpFlow/API_reqs";

const PaymentConfirm = (props) => {
  useEffect(() => {});
  const { 
    headerHeight,
    mode
   } =
    useSignUpFlowContext();

  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  process.env.REACT_APP_ENV === "prod"
    ? Payhere.init("224315", AccountCategory.LIVE)
    : Payhere.init("1224576", AccountCategory.SANDBOX);

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [domain, setDomain] = useState("");
  const [numberOfEmp, setNumberOfEmp] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [agree, setAgree] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const [invoice, setInvoice] = useState("");
  const [amount, setAmount] = useState("");
  const [item, setItem] = useState("");
  const [hash, setHash] = useState("");
  const [redirect, setRedirect] = useState("/signup-flow-details");

  function onPayhereSubscriptionError(errorMsg) {
    alert(errorMsg);
  }

  const getInvoice = () => {
    let data ={
      amount: amount,
      currency: currency,
      items: item,
      first_name:firstName,
      last_name: secondName,
      business_name: domain,
      job_title: "",
      accounts: numberOfEmp.toString(),
      phone_number: number,
      email: email
    }
    if (amount != "") {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "post",
        // baseURL: "http://localhost:4000",
        baseURL: config.apiEndpoints.protocol + config.apiEndpoints.authServer,
        url: "/invoice/create",
        data: data,
      })
      .then((response) => {
        setInvoice(response.data.invoice_id);
        setTimeout(() => {
          getHash(response.data.invoice_id);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  const getHash = (invoice_id) => {
    let requestParam =
      "?amount=" + amount + "&currency=" + currency + "&invoice=" + invoice_id;
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      // baseURL: "http://localhost:4000",
      baseURL: config.apiEndpoints.protocol + config.apiEndpoints.authServer,
      url: "/payhere/hash" + requestParam,
    })
      .then((response) => {
        setHash(response.data.payhere_hash);
      })
      .catch((error) => {
        // reject(error);
      });
  };

  const handlePayhere = () => {
    try {
      const customer = new Customer({
        first_name: firstName,
        last_name: secondName,
        phone_number: number,
        email: email,
        address: address,
        city: city,
        country: country,
      });

      const subscriptionData = new SubscriptionParams({
        returnUrl: config.domain.app + '/admin',
        cancelUrl: config.domain.app + '/admin',
        notifyUrl:
          config.apiEndpoints.protocol +
          config.apiEndpoints.authServer +
          "/payhere/notify-update",
        order_id: invoice,
        itemTitle: item,
        recurrence: new Month(1),
        duration: "Forever",
        currency: currency == "USD" ? CurrencyType.USD : CurrencyType.LKR,
        amount: amount,
        hash: hash,
      });
      const subscription = new PayhereSubscription(
        customer,
        subscriptionData,
        onPayhereSubscriptionError
      );
      subscription.start();
    } catch (err) {
      console.log(err);
    }
  };

  const getOnboardingDetails = () => {
    let onboardingId = new URLSearchParams(location.search).get('id')
    let count = new URLSearchParams(location.search).get('count')
    setNumberOfEmp(count);

    switch (count) {
      case "10":
        setAmount("9.00");
        break;
      case "20":
        setAmount("15.00");
        break;
      default:
        setAmount((count * 1).toString() + ".00");
        break;
    }
    setItem(count + " Business Mail");

    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      // baseURL: "http://localhost:4000",
      baseURL: config.apiEndpoints.protocol + config.apiEndpoints.authServer,
      url: "/onboard?onboard_id=" + onboardingId,
    })
    .then((response) => {
      setFirstName(response.data.first_name);
      setSecondName(response.data.last_name);
      setNumber(response.data.contact_number);
      setEmail(response.data.email);
      setAddress(response.data.address);
      setCity(response.data.city);
      setCountry(response.data.country);
      setDomain(response.data.domain);
      getInvoice();
    })
    .catch((error) => {
      // reject(error);
    });
  }

  const getPayhereAuth = () => {
    let basicAuth = "Basic " + btoa("4OVxMiNc3cm4JDDlaFpctm3D2:4JJv1SiGEAH8m7CSeYcJsK4qBquWCdhTf4eVYu0Wwc5p");
    const params = new URLSearchParams();
    params.append('grant_type', 'client_credentials');
    axios({
      headers: {
        "Authorization": basicAuth,
        "Content-Type": "application/x-www-form-urlencoded"
      },
      method: "post",
      baseURL: 'https://sandbox.payhere.lk',
      url: "/merchant/v1/oauth/token",
      data: params
    })
    .then((response) => {
      console.log("payhere: ",response);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    getOnboardingDetails()
    // getPayhereAuth()
  }, []);

  useEffect(() => {
    getInvoice();
  }, [domain]);

  return (
    <>
      <div className={`SignupFlow-${mode}`}>
        <header className="header-container">
          <SignupNavbar />
        </header>
      </div>
      <div className={`main-body-pay-${mode}`}
        style={{
          height: `calc(100vh - ${headerHeight}px)`,
        }}>
        <center className="inputs-wrapper">
          <h2
            style={{
              marginBottom: "20px",
            }}>Billing details</h2>

          <div>
            Name : {firstName} {secondName}
            <br />
            Email : {email}
            <br />
            Contact Number : {number}
            <br />
            Address : {address}
            <br />
            City : {city}
            <br />
            Country : {country}
            <br />
            Domain : {domain}
            <br />
            Number of Accounts : {numberOfEmp}
            <br />
            Total Amount : {currency} {amount}
          </div>
          <div style={{ 
            display: "flex",
            justifyContent: "center",
          }}>
            <div
              className={`check-container ${agreeError ? "check-error" : ""}`}
              onClick={() => {
                setAgree(!agree);
                setAgreeError(false);
              }}
            >
              <div
                className={`check-box ${
                  agreeError ? "checked-error" : agree ? "checked" : ""
                }`}
              >
                {agree && <img alt="" src={check} className="check" />}
              </div>
              I agree to the{" "}
              <a
                href="https://getzapmail.com/legal/"
                style={{ marginLeft: "5px" }}
              >
                Terms & Conditions
              </a>
            </div>
          </div>
          <div className="pay-verify">
            <SignupButtonContainer
              title="Confirm & Pay"
              line
              onClick={() => {
                if (!agree) {
                  setAgreeError(true);
                  return;
                }
                handlePayhere();
              }}
            />
          </div>
          <a href={config.domain.app + '/admin'} className="back-link">Go Back</a>
        </center>
      </div>
    </>
  );
};

export default PaymentConfirm;
