import React, { useState, useEffect, useRef } from "react";
import AppContext from "../../../ContextApi/AppContext";
import "./ForgotPassword.scss";
import danger from "../../../Assets/Icons/vuesax-linear-danger.svg";
import UsernameInput from "../../../Components/UsernameInput/UsernameInput";
import Button from "../../../Components/Button/Button";
import check from "../../../Assets/Icons/checked.png";
import cross from "../../../Assets/Icons/cross.png";
import logo from "../../../Assets/Images/logo.png";
import { useLocation, useHistory } from "react-router-dom";
import Done from "../../../Assets/Animations/animation_lm0b09rp.json";
import Modal from "../SignUpFlow/Components/Modal/Modal";
const ForgotPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const forgetBtn = useRef(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [isNewPasswordFocus, setIsNewPasswordFocus] = useState(false);
  const [isConfirmPasswordFocus, setIsConfirmPasswordFocus] = useState(false);
  const [pwdLength, setPwdLength] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [numVal, setNumVal] = useState(false);
  const [passwordInputStatus, setPasswordInputStatus] = useState(0);
  const [confirmPassStatus, setConfirmPassStatus] = useState(0);
  const [pwdFormat, setPwdFormat] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const passwordValidation = () => {
    let passwordValue = newPassword;
    if (passwordValue.length === 0 || passwordValue === "") {
      setPasswordInputStatus(0);
      setPwdLength(false);
      setLowerCase(false);
      setUpperCase(false);
      setSpecialChar(false);
      setNumVal(false);
    } else {
      if (/[A-Z]/.test(passwordValue)) {
        setUpperCase(true);
      } else {
        setUpperCase(false);
      }
      if (/[a-z]/.test(passwordValue)) {
        setLowerCase(true);
      } else {
        setLowerCase(false);
      }
      if (/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(passwordValue)) {
        setSpecialChar(true);
      } else {
        setSpecialChar(false);
      }

      if (/\d/.test(passwordValue)) {
        setNumVal(true);
      } else {
        setNumVal(false);
      }
      if (passwordValue.length < 8) {
        setPwdLength(false);
      } else {
        setPwdLength(true);
      }
      if (
        passwordValue.length >= 8 &&
        numVal &&
        upperCase &&
        lowerCase &&
        specialChar
      ) {
        setPasswordInputStatus(1);
        setPwdFormat(false);
      } else {
        setPasswordInputStatus(0);
        setPwdFormat(true);
      }
    }
  };
  useEffect(() => {
    passwordValidation();
  }, [newPassword]);
  const handleConfirm = (e) => {
    let confirm_password = e.target.value;
    if (confirmPassword.length > 0) {
      if (confirmPassword !== newPassword) {
        setConfirmPassStatus(2);
      } else {
        setConfirmPassStatus(1);
      }
    } else {
      setConfirmPassStatus(0);
    }
    if (confirmPasswordError) {
      if (confirm_password !== "") {
        setConfirmPasswordError(false);
      }
    }
    setConfirmPassword(confirm_password);
  };
  useEffect(() => {
    if (
      newPassword.length >= 8 &&
      numVal &&
      upperCase &&
      lowerCase &&
      specialChar
    ) {
      setPasswordInputStatus(1);
      setPwdFormat(false);
    }
  });
  const handlePassword = (e) => {
    let passwordValue = e.target.value;
    if (pwdError) {
      if (passwordValue !== "") {
        setPwdError(false);
      }
    }
    setNewPassword(passwordValue);
  };
  const confirmPassValidation = () => {
    if (confirmPassword.length > 0) {
      if (confirmPassword !== newPassword) {
        return (
          <div className="form-fail">
            <img src={danger} alt="" className="danger-login" />
            <p>Please make sure passwords match.</p>
          </div>
        );
      } else if (confirmPassStatus === 1) {
        return "";
      }
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter") {
        if (newPassword === "" && confirmPassword === "") {
          setPwdError(true);
          setConfirmPasswordError(true);
        } else if (newPassword === "" && confirmPassword !== "") {
          setPwdError(true);
        } else if (newPassword !== "" && confirmPassword === "") {
          setConfirmPasswordError(true);
        } else {
          setShowPassword(false);
          setPwdError(false);
          setConfirmPasswordError(false);
          forgetBtn.current.click();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [newPassword, confirmPassword]);
  const removeFocus = () => {
    setIsNewPasswordFocus(false);
    setIsConfirmPasswordFocus(false);
  };
  const modalMessage = () => {
    return (
      <>
        Password reset successfully
      </>
    )
  }
  return (
    <AppContext.Consumer>
      {(context) => (
        <div className={`forget-${context.mode}`}>
          <Modal
            show={showModal}
            onClose={() => {
              setShowModal(false)
            }}
            lottie={Done}
            signUpFlow
            signUpFlowLottie
            message={modalMessage()}
            fontSize="1.35rem"
          />
          <div className="_forgetContainer">
            <div className="form-wrapper">
              <div className="header-container">
                <img alt="" src={logo} className="image" />
              </div>
              <div className="forgetwrapper">
                <div className="header">Forgot Password</div>
                <div className="input-wrapper">
                  <UsernameInput
                    type={showPassword ? "text" : "password"}
                    placeholder={isNewPasswordFocus ? "" : "New Password"}
                    onChange={(e) => {
                      handlePassword(e);
                    }}
                    isPwdFocus={isNewPasswordFocus}
                    pwdError={pwdError}
                    onFocus={() => setIsNewPasswordFocus(true)}
                    onBlur={() => {
                      setIsNewPasswordFocus(false);
                    }}
                    name="password"
                    value={newPassword}
                  />
                  {pwdError ? (
                    <div className="form-fail">
                      * Required
                    </div>
                  ) : (
                    ""
                  )}
                  {pwdFormat &&
                    !isNewPasswordFocus &&
                    newPassword.length !== 0 ? (
                    <div className="form-fail">
                      <img src={danger} alt="" className="danger-forget" />
                      The Password does not meet requirements
                    </div>
                  ) : (
                    ""
                  )}
                  {isNewPasswordFocus &&
                    newPassword.length !== 0 &&
                    passwordInputStatus !== 1 ? (
                    <div className="condition-strength-2">
                      <div className={upperCase ? "item" : "item2"}>
                        {" "}
                        {upperCase ? (
                          <img src={check} alt="" className="danger-image2" />
                        ) : (
                          <img src={cross} alt="" className="danger-image2" />
                        )}
                        Contains an uppercase letter
                      </div>
                      <div className={lowerCase ? "item" : "item2"}>
                        {lowerCase ? (
                          <img src={check} alt="" className="danger-image2" />
                        ) : (
                          <img src={cross} alt="" className="danger-image2" />
                        )}
                        Contains a lowercase letter
                      </div>
                      <div className={numVal ? "item" : "item2"}>
                        {numVal ? (
                          <img src={check} alt="" className="danger-image2" />
                        ) : (
                          <img src={cross} alt="" className="danger-image2" />
                        )}
                        Contains a number
                      </div>
                      <div className={specialChar ? "item" : "item2"}>
                        {specialChar ? (
                          <img src={check} alt="" className="danger-image2" />
                        ) : (
                          <img src={cross} alt="" className="danger-image2" />
                        )}
                        Contains a special character
                      </div>
                      <div className={pwdLength ? "item" : "item2"}>
                        {pwdLength ? (
                          <img src={check} alt="" className="danger-image2" />
                        ) : (
                          <img src={cross} alt="" className="danger-image2" />
                        )}
                        Password strength 8 characters
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="input-wrapper">
                  <UsernameInput
                    type={showPassword ? "text" : "password"}
                    placeholder={
                      isConfirmPasswordFocus ? "" : "Confirm Password"
                    }
                    onChange={(e) => {
                      handleConfirm(e);
                    }}
                    isPwdFocus={isConfirmPasswordFocus}
                    pwdError={confirmPasswordError}
                    onFocus={() => setIsConfirmPasswordFocus(true)}
                    onBlur={() => setIsConfirmPasswordFocus(false)}
                    name="password"
                    value={confirmPassword}
                  />
                  {confirmPassValidation()}
                </div>
                <div className="margin" />
                <Button
                  ref={forgetBtn}
                  loading={context?.forgetIsLoading}
                  buttonText="Reset Password"
                  onClick={(e) => {
                    e.preventDefault();
                    removeFocus();
                    if (newPassword === "" && confirmPassword === "") {
                      setPwdError(true);
                      setConfirmPasswordError(true);
                    } else if (newPassword === "" && confirmPassword !== "") {
                      setPwdError(true);
                    } else if (newPassword !== "" && confirmPassword === "") {
                      setConfirmPasswordError(true);
                    } else {
                      let email = location.state && location.state.email
                      let x_access_token = location.state && location.state.x_access_token
                      context?.forgetPassword(email, newPassword, confirmPassword, x_access_token).then((success) => {
                        if (success) {
                          setShowModal(true)
                          setTimeout(() => {
                            setShowModal(false)
                            history.push({
                              pathname: "/",
                            })
                          }, 2000);
                        }
                      })
                    }
                    if (context.forgetSuccess) {
                      setNewPassword("");
                      setConfirmPassword("");
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default ForgotPassword;
