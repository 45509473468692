import React from "react";
import Lottie from "lottie-react";
import "./ModalStyles.scss";
import AppContext from "../../../../../ContextApi/AppContext";

const Modal = (props) => {
  if (!props.show) {
    return null;
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      // Close the modal when clicking on the overlay
      props.onClose();
    }
  };

  return (
    <AppContext.Consumer>
      {(context) => (
        <div className="modal" onClick={handleOverlayClick}>
          <div
            className={`modal-content-${context.mode}`}
            style={{
              width: props.width ? props.width : "",
              height: props.height ? props.height : "",
            }}
          >
            <center>
              <div className="modal-header">
                {props.headerImage ? (
                  <img
                    alt=""
                    src={props.headerImage}
                    className={props.signUpFlow ? "signUpModal" : ""}
                  />
                ) : props.lottie ? (
                  <Lottie
                    animationData={props.lottie}
                    loop={false}
                    className={props.signUpFlowLottie ? "signUpModalLottie" : ""}
                  />
                ) : (
                  ""
                )}
                <h4 className="modal-title">{props.title}</h4>
              </div>
            </center>
            <div
              className="modal-body"
              style={{ fontSize: props.fontSize ? props.fontSize : "" }}
            >
              <p>{props.message}</p>

            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default Modal;
