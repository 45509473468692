import React, { useEffect, useState } from "react";
import InputContainer from "../Signup/InputContainer/InputContainer";
import SignupButtonContainer from "../Signup/SignupButtonContainer/SignupButtonContainer";
import "./index.scss";
import { useSignUpFlowContext } from "../SignUpFlow/GlobalStates";
import { useHistory, useParams } from "react-router-dom";
import {
  sendOTP,
  validateOTP,
  prePaymentUserInfo,
} from "../SignUpFlow/API_reqs";
import SignupNavbar from "../SignUpFlow/Components/SignupNavbar/SignupNavbar";
import { countryCodes } from "../SignUpFlow/GlobalStates";

const PaymentBasic = (props) => {
  useEffect(() => { });
  const {
    selectedItem,
    setSelectedItem,
    firstName,
    setFirstName,
    secondName,
    setSecondName,
    numberOfEmp,
    setNumberOfEmp,
    email,
    setEmail,
    headerHeight,
    number,
    setNumber,
    mode
  } = useSignUpFlowContext();

  const history = useHistory();
  const params = useParams();

  const [numberOfEmpError, setnumberOfEmpError] = useState(false);
  const [numberOfEmpErrorText, setNumberOfEmpErrorText] = useState("Error");
  const [emailError, setemailError] = useState(false);
  const [emailErrorText, setemailErrorText] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState("Enter a valid mobile number");
  const [countryCode, setCountryCode] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpDone, setOtpDone] = useState(false);
  const [timer, setTimer] = useState(0);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorText, setOtpErrorText] = useState("");
  const [mobileError, setMobileError] = useState(false);

  const handleCountryCode = (e) => {
    setCountryCode(e.target.value);
  }

  const handleOTP = () => {
    if (!showOtp && number.length > 6) {
      sendOTP({ email: email })
        .then(async (response) => {
          if (response.status === 201) {
            setOtpSent(true);
            localStorage.setItem("otp", Date.now() + 90000);
            setTimer(90000);
          }
        })
        .catch((error) => {
          setemailError(true);
          setemailErrorText("OTP Sending error. Please try again.");
        });
    }
    else {
      setMobileError(true);
    }
  };

  const handleOTPVerify = () => {
    validateOTP({
      email: email,
      otp: otp,
    })
      .then(async (response) => {
        if (response.status === 200) {
          setOtpError(false);
          setemailError(false);
          setOtpDone(true);
          handlePayment();
        } else if (response.status === 401) {
          setOtpErrorText("Invalid OTP. Please try again.");
          setOtpError(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setOtpErrorText("Invalid OTP. Please try again.");
        } else if (error.response.status === 403) {
          setOtpErrorText("OTP Expired. Please request another OTP");
        }
        setOtpError(true);
      });
  };

  const handlePayment = () => {
    setNumber(countryCode + number);
    history.push("/confirm/other");
  };

  const setPhoneNumber = (phoneNum) => {
    const element = document.getElementById('countryCode');
    let code = element.value;
    if (code !== "") {
      setNumber(phoneNum);
      setMobileError(false);
      setPhoneErrorText("Invalid Phone Number")
    }
    else {
      setMobileError(true);
      setPhoneErrorText("Country Code is required");
    }
  }

  useEffect(() => {
    switch (params.item) {
      case "zappy":
        setSelectedItem(1);
        break;
      case "bulk":
        setSelectedItem(2);
        break;
      case "pack1":
        setNumberOfEmp(10);
        setSelectedItem(4);
        break;
      case "pack2":
        setNumberOfEmp(3);
        setSelectedItem(5);
      case "pack3":
        setNumberOfEmp(30);
        setSelectedItem(6);
      case "cyber-starter":
        setNumberOfEmp(10);
        setSelectedItem(7);
        break;
      case "connect-plus":
        setNumberOfEmp(3);
        setSelectedItem(8);
        break;
      case "growth":
        setNumberOfEmp(10);
        setSelectedItem(9);
        break;
      case "zappy-u":
        setSelectedItem(10);
      case "bulk-u":
        setSelectedItem(11);
        break;
    }

    let otp = localStorage.getItem("otp");
    if (otp) {
      if (Date.now() < otp) {
        setOtpSent(true);
        setTimer(otp - Date.now());
      } else {
        localStorage.removeItem("otp");
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (timer > 0) setTimer(timer - 1000);
    }, 1000);
  }, [timer]);

  return (
    <>
      <div className={`SignupFlow-${mode}`}>
        <header className="header-container">
          <SignupNavbar />
        </header>
      </div>
      <div
        className={`main-body-pay-${mode}`}
        style={{
          height: `calc(100vh - ${headerHeight}px)`,
        }}
      >
        <center className="inputs-wrapper">
          <h2>Submit your details</h2>

          <InputContainer
            color={mode === "darkmode" ? "white" : "#000"}

            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="First Name"
            value={firstName}
            type="text"
            display={otpDone ? "none" : "flex"}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            error={false}
            errorText={"otpErrorText"}
          />

          <InputContainer
            color={mode === "darkmode" ? "white" : "#000"}

            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="Last Name"
            value={secondName}
            type="text"
            display={otpDone ? "none" : "flex"}
            onChange={(e) => {
              setSecondName(e.target.value);
            }}
            error={false}
            errorText={"otpErrorText"}
          />
          <div className="DatePicker">
            <select className="selector" placeholder="countryCode" id="countryCode" name="countryCode" onChange={handleCountryCode}>
              <option value="">--</option>
              {countryCodes.map((countryCode, i) => {
                return (
                  <option
                    className="select-option-style"
                    key={i}
                    value={countryCode.dial_code}
                  >
                    {countryCode.dial_code} - {countryCode.name}
                  </option>
                );
              })}
            </select>
            <InputContainer
              color={mode === "darkmode" ? "white" : "#000"}

              height="4.18rem"
              fontSize="1rem"
              marginTop="1.088rem"
              placeholder="Phone"
              value={number}
              type="text"
              display="flex"
              onChange={(e) => {
                const regex = /^[0-9+]+$/;
                if (e.target.value === "" || regex.test(e.target.value)) {
                  setPhoneNumber(e.target.value)
                }
              }}
              error={mobileError}
              errorText={phoneErrorText}
            />
          </div>

          {showOtp ? (
            <InputContainer
              color={mode === "darkmode" ? "white" : "#000"}

              height="4.18rem"
              fontSize="1rem"
              marginTop="1.688rem"
              placeholder="Number of Accounts"
              value={numberOfEmp}
              type="number"
              onChange={(e) => {
                setNumberOfEmp(e.target.value);
                if (e.target.value > 0) {
                  setnumberOfEmpError(false);
                } else {
                  setnumberOfEmpError(true);
                  setNumberOfEmpErrorText("Number of Accounts is required");
                }
              }}
              error={numberOfEmpError}
              errorText={numberOfEmpErrorText}
            />
          ) : (
            ""
          )}

          <InputContainer
            color={mode === "darkmode" ? "white" : "#000"}

            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="Email"
            value={email}
            type="text"
            display={otpDone ? "none" : "flex"}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            error={emailError}
            errorText={emailErrorText}
          />

          <div className="pay-sub-text">
            {!otpSent ? (
              <SignupButtonContainer
                title="Send OTP"
                line
                onClick={handleOTP}
              />
            ) : timer > 0 ? (
              <>
                OTP sent to <i>{email}</i>
                <br />
                Time left: {Math.floor(timer / 1000)}s
              </>
            ) : (
              <SignupButtonContainer
                title="Resend OTP"
                line
                onClick={handleOTP}
              />
            )}
          </div>

          <div style={{ display: otpSent ? "flex" : "none" }}>
            <InputContainer
              color={mode === "darkmode" ? "white" : "#000"}

              height="4.18rem"
              fontSize="1rem"
              marginTop="1.088rem"
              placeholder="OTP"
              value={otp}
              type="text"
              display="flex"
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              error={otpError}
              errorText={otpErrorText}
            />
            <div className="pay-verify">
              <SignupButtonContainer
                title="Verify OTP"
                line
                onClick={handleOTPVerify}
              />
            </div>
          </div>

          {otpDone ? "OTP verified" : ""}

          <div style={{ display: otpDone ? "flex" : "none" }}>
            <SignupButtonContainer
              title="Go to pay"
              line
              onClick={handlePayment}
            />
          </div>
        </center>
      </div>
    </>
  );
};

export default PaymentBasic;
