import React, { useState, useEffect, useRef, useContext } from "react";
import AppContext from "../../../ContextApi/AppContext";
import { useHistory, useLocation } from "react-router-dom";
import "./RecoveryEmailStyles.scss";
import UsernameInput from "../../../Components/UsernameInput/UsernameInput";
import Button from "../../../Components/Button/Button";
import logo from "../../../Assets/Images/logo.png";
import Modal from "../SignUpFlow/Components/Modal/Modal";
import Done from "../../../Assets/Animations/animation_lm0b09rp.json";
import config from "../../../config"
const RecoveryEmail = () => {
    const [selectedPage, setSelectedPage] = useState(1)
    const [otp, setOtp] = useState("");
    const [email, setEmail] = useState("");
    const forgetBtn = useRef(null);
    const [otpError, setOtpError] = useState(false);
    const [isNewPasswordFocus, setIsNewPasswordFocus] = useState(false);
    const [otpErrorText, setOtpErrorText] = useState("*Required");
    const [emailError, setEmailError] = useState(false)
    const [errorText, setErrorText] = useState("* Required")
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const context = useContext(AppContext)
    useEffect(() => {
        if (location.state === undefined) {
            history.push({
                pathname: "/",
            });
        }
    }, [])
    const validateEmail = () => {
        console.log(email);
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    };
    const modalMessage = () => {
        return (
          <>
            Secondary email updated successfully
          </>
        )
      }
    const emailPage = () => {
        return (
            <div className="forgetwrapper">
                <div className="header">Add a Secondary Email</div>
                <div className="margin" />
                <div className="subtext">This will be used to recover your account</div>
                <div className="input-wrapper">
                    <UsernameInput placeholder="Recovery email address"
                        value={email}
                        type="email"
                        onChange={e => {
                            setEmail(e.target.value)
                            setEmailError(false)
                        }}
                        userNameError={emailError}
                        onBlur={() => {
                        }}
                        onFocus={() => {
                        }}
                    />
                    {emailError ? (
                        <div className="form-fail">{errorText}</div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="margin" />
                <Button
                    ref={forgetBtn}
                    loading={context?.forgetIsLoading}
                    buttonText="Send OTP"
                    onClick={() => {
                        if (email !== "") {
                            if (validateEmail()) {
                                context?.recovery_email(email).then((response) => {
                                    if (response) {
                                        setEmailError(false)
                                        setSelectedPage(2)
                                    }
                                })

                            } else {
                                setEmailError(true)
                                setErrorText("Invalid email format")
                            }
                        } else {
                            setEmailError(true)
                            setErrorText("* Required")
                        }
                    }}
                />
                <div className="margin-btn" />
            </div>
        )
    }
    const otpPage = () => {
        return (
            <div className="forgetwrapper">
                <div className="header">Add a Secondary Email</div>
                <div className="margin" />
                <div className="subtext">An email with a verification code was just sent to {email}</div>
                <div className="input-wrapper">
                    <UsernameInput
                        type={"password"}
                        placeholder={"OTP"}
                        onChange={(e) => {
                            setOtpError(false)
                            setOtp(e.target.value)
                            if (e.target.value.length === 6) {
                                context.recovery_email_otp(e.target.value).then((data) => {
                                    if (data) {
                                        setShowModal(true)
                                        setTimeout(() => {
                                          setShowModal(false)
                                          window.location.href = `${config.domain.app}`;
                                        }, 2000);
                                      }
                                }).catch((error) => {
                                    if (error.response.status === 401) {
                                        setOtpErrorText("Invalid OTP. Please try again.");
                                    } else if (error.response.status === 403) {
                                        setOtpErrorText(
                                            "OTP Expired. Please request another OTP"
                                        );
                                    }
                                    setOtpError(true);
                                });
                            }
                        }}
                        isPwdFocus={isNewPasswordFocus}
                        pwdError={otpError}
                        onBlur={() => {
                        }}
                        onFocus={() => {
                        }}
                        value={otp}
                        maxLength={6}

                    />
                    {otpError ? (
                        <div className="form-fail">
                            {otpErrorText}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="margin" />
                <Button
                    ref={forgetBtn}
                    loading={context?.forgetIsLoading}
                    buttonText="Submit"
                    onClick={() => {
                        if (otp !== "" && otp.length === 6) {

                            context.recovery_email_otp(otp).then((data) => {
                                if (data) {
                                    setShowModal(true)
                                    setTimeout(() => {
                                      setShowModal(false)
                                      window.location.href = `${config.domain.app}`;
                                    }, 2000);
                                  }
                            }).catch((error) => {
                                if (error.response.status === 401) {
                                    setOtpErrorText("Invalid OTP. Please try again.");
                                } else if (error.response.status === 403) {
                                    setOtpErrorText(
                                        "OTP Expired. Please request another OTP"
                                    );
                                }
                                setOtpError(true);
                            });
                        } else if (otp === "" || otp.length < 6) {
                            setOtpErrorText(
                                "*Required"
                            );
                            setOtpError(true)
                        }
                    }}
                />
                <div className="margin-btn" />
            </div>
        )
    }
    const getPage = () => {
        let page = emailPage()
        switch (selectedPage) {
            case 1:
                page = emailPage()
                break
            case 2:
                page = otpPage()
                break
            default:
                page = emailPage()
                break;
        }
        return page
    }
    return (
        <AppContext.Consumer>
        {(context) => (
        <div className={`forget-${context.mode}`}>
             <Modal
            show={showModal}
            onClose={() => {
              setShowModal(false)
            }}
            lottie={Done}
            signUpFlow
            signUpFlowLottie
            message={modalMessage()}
            fontSize="1.35rem"
          />
            <div className="_forgetContainer">
                <div className="form-wrapper">
                    <div className="header-container">
                        <img alt="" src={logo} className="image" />
                    </div>
                    {getPage()}
                </div>
            </div>
        </div>)}</AppContext.Consumer>
    );
};
export default RecoveryEmail;
