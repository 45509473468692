import React from "react";

const ProgressBar = (props) => {
  const percentage = `${props.percentage}`;

  // Define the CSS style for the progress bar
  const progressBarStyle = {
    backgroundImage: `linear-gradient(to right, #147CFF ${percentage}%, #ACACAC ${percentage}%)`,
  };

  return (
    <div style={progressBarStyle}>
      <p style={{ color: "#147CFF" }}>{percentage}</p>
    </div>
  );
};

export default ProgressBar;
