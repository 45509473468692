import React, { useState } from "react";
import SignupWrapper from "./SignupWrapper/SignupWrapper";
import SignupWrapperTwo from "./SignupWrapperTwo/SignupwrapperTwo";
import SignupWrapperThree from "./SignupWrapperThree/SignupWrapperThree";
import SignupWrapperFour from "./SignupWrapperFour/SignupWrapperFour";
import DomainWrapper from "./DomainWrapper/DomainWrapper";
import SignupSuccess from "./SignupSuccess/SignupSuccess";
const Signup = () => {
    const [selectedPage, setSelectedPage] = useState(1)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [domain, setDomain] = useState("")
    const [date, setDate] = useState("");
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");

    const getSignupWrapper = () => {
        return <SignupWrapper setSelectedPage={setSelectedPage}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            date={date}
            setDate={setDate}
            month={month}
            setMonth={setMonth}
            year={year}
            setYear={setYear} />
    }


    const getPage = () => {
        let page = getSignupWrapper()
        switch (selectedPage) {
            case 1:
                page = getSignupWrapper()
                break;
            case 2:
                page = <SignupWrapperTwo
                    setSelectedPage={setSelectedPage}
                    email={email}
                    setEmail={setEmail} />
                break;
            case 3:
                page = <DomainWrapper
                    setSelectedPage={setSelectedPage}
                    setDomain={setDomain} />
                break;
            case 4:
                page = <SignupWrapperThree
                    setSelectedPage={setSelectedPage}
                    username={username}
                    setUsername={setUsername}
                    domain={domain} />
                break;
            case 5:
                page = <SignupWrapperFour setSelectedPage={setSelectedPage}
                    firstName={firstName}
                    lastName={lastName}
                    date={date}
                    month={month}
                    year={year}
                    email={email}
                    username={username}
                    domain={domain}
                    password={password}
                    setPassword={setPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                />
                break;
            case 6:
                page = <SignupSuccess />
                break;
            default:
                page = getSignupWrapper()
                break;
        }
        return page
    }
    return (
        getPage()
    )

}
export default Signup