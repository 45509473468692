import React, { useState, useEffect } from 'react';
import moment from 'moment';
import "./DatePickerStyles.scss"


const DatePicker = (props) => {

  const [generatedYears, setGeneratedYears] = useState([]);
  const [generatedMonths, setGeneratedMonths] = useState([]);
  const [generatedDays, setGeneratedDays] = useState([]);
  const handleDateChange = (e) => {
    props.setDate(e.target.value);
    props.setDateError(false)
  };

  const handleMonthChange = (e) => {
    localStorage.setItem(
      "selected_birthday_month",
      e.target.value
    );
    props.setMonth(e.target.value);
    props.setMonthError(false)
    props.setAgeError(false)
    generateDays();
  };

  const handleYearChange = (e) => {
    localStorage.setItem(
      "selected_birthday_year",
      e.target.value
    );
    props.setYear(e.target.value);
    props.setYearError(false)
    props.setAgeError(false)
    generateMonths();
  };
  useEffect(() => {
    generateYears();
  }, []);
  const generateYears = () => {
    let thisYear = moment().format("YYYY");
    let generatedYears = [];
    let x = 100;

    for (x; x >= 0; x--) {
      let y = parseInt(thisYear) - parseInt(x);

      generatedYears.push(y);
    }
    setGeneratedYears(generatedYears);
    props.setAgeError(false)
    generateMonths();
  };

  const generateMonths = () => {
    let getMonths = moment().month(1);
    let allMonths = getMonths._locale._months;
    setGeneratedMonths(allMonths);
    generateDays();
  };
  const generateDays = () => {
    let selectedMonthX =
      localStorage.getItem("selected_birthday_month") || "February";
    let selectedYearX =
      localStorage.getItem("selected_birthday_year") || "1990";
    let daysInMonth = [];
    let selectedMonthNumber =
      selectedMonthX && moment().month(selectedMonthX).format("M");
    let selectedMonthAndYear = `${selectedYearX +
      "-" +
      (selectedMonthNumber > 10
        ? selectedMonthNumber
        : "0" + selectedMonthNumber)
      }`;
    let monthDate = moment(selectedMonthAndYear).startOf("month");
    for (let i = 0; i < monthDate.daysInMonth(); i++) {
      let newDay = monthDate.clone().add(i, "days");
      daysInMonth.push(newDay.format("DD"));
    }
    setGeneratedDays(daysInMonth);
  };

  return (
    <div className="DatePicker">
      <select value={props.date} onChange={handleDateChange} className="selector" style={{ color: props.date === "" ? "#a6a6a6" : "", borderColor: props.dateError ? "#D85757" : "" }}>
        <option value=""
        >Day</option>
        {generatedDays.map((days, i) => {
          return (
            <option
              className="select-option-style"
              key={i}
              value={days}
            >
              {days}
            </option>
          );
        })}
      </select>
      <select value={props.month} onChange={handleMonthChange} className="selector-month" style={{ color: props.month === "" ? "#a6a6a6" : "", borderColor: props.monthError ? "#D85757" : "" }}>
        <option value="">Month</option>
        {generatedMonths.map((months, i) => {
          return (
            <option
              key={i}
              value={months}
            >
              {months}
            </option>
          );
        })}
      </select>
      <select value={props.year} onChange={handleYearChange} className="selector" style={{ color: props.year === "" ? "#a6a6a6" : "", borderColor: props.yearError ? "#D85757" : "" }}>
        <option value="">Year</option>
        {generatedYears
          .slice()
          .reverse()
          .map((year, i) => {
            return (
              <option
                key={i}
                value={year}
              >
                {year}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default DatePicker;
