import React from "react";
import "./SignupButtonContainerStyles.scss"

const SignupButtonContainer = (props) => {
    return (
        <div className={`ButtonContainer ${props.primary ? "ButtonContainer-Primary" : props.danger ? "ButtonContainer-Danger" : props.cancel ? "ButtonContainer-Cancel" : "ButtonContainer-Secondary"}`}
            onClick={props.onClick}
            style={{ width: props.width ? props.width : "" }}
        >
            {props.isLoading ? <div className="sign-loader" />
                : props.title}
        </div>
    )

}
export default SignupButtonContainer