import React, { useState, useEffect } from "react";
import "./SignupSuccessStyles.scss"
import logo from "../../../../Assets/Images/single-logo.png";
import twitter from "../../../../Assets/Images/twitter.png";
import linkedin from "../../../../Assets/Images/linkedin.png";
import insta from "../../../../Assets/Images/instagram.png";
import fb from "../../../../Assets/Images/facebook.png";
import envelop from "../../../../Assets/Images/envelope.png";
import axios from "axios";
import config from "../../../../config"


const SignupSuccess = () => {
    const [copied, setCopied] = useState(false)
    const [count, setCount] = useState(0)
    const getUserCount = () => {
        return new Promise((resolve, reject) => {
            axios({
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
                method: "get",
                baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
                url: `/user/count`,
            })
                .then(async (response) => {
                    if (response.status === 200) {
                        setCount(response.data.count)
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }
    useEffect(() => {
        getUserCount()
    }, [])
    let subject = "Simplify Your Inbox, Personalize Your Email - Try Zapmail for Free!"
    let body =
        `Hey there,I wanted to share an amazing app called Zapmail that's revolutionizing the way we use email. It's a conversational email platform that simplifies your inbox and lets you personalize your email experience.The best part? You can try it out for free with any available domain of your choice. It's an incredible opportunity to showcase your unique personality and make your emails truly stand out.Give Zapmail a try today by visiting https://zapmail.app/. Happy emailing!`
    const data = [
        {
            "image": twitter,
            link: `https://twitter.com/intent/tweet?&text=%40ZapmailHQ%20is%20changing%20emails%20as%20we%20know%20it.%20Enjoy%20the%20simplicity%20of%20conversational%20emails%20with%20the%20domain%20of%20your%20choice.%20Check%20it%20out👉%3A%20https%3A%2F%2Fzapmail.app%2F%20%23DitchGenericEmails%20%23Zapmail%20&`
        },
        {
            "image": linkedin,
            link: `https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fzapmail.app`
        }, {
            "image": insta,
            link: `https://www.instagram.com/zapmailhq/`
        }, {
            "image": fb,
            link: `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fzapmail.app%2F&amp%3Bsrc=sdkpreparse`
        }, {
            "image": envelop,
            link: "mailto:" + "" + '?cc=' + "" + '&subject=' + subject + '&body=' + body
        },

    ]
    const url = `https://zapmail.app`
    return (
        <div className="SignupSuccess-Wrapper">

            <div className="SignupSuccess-Container">

                <div className="logo-container">
                    <div className="logo-wrapper">
                        <img alt="" src={logo} className="logo" />
                    </div>

                </div>
                <div className="header-container-success">
                    <div className="signup-header">
                        You’re #{count} in line
                    </div>
                    <div className="signup-subheader">
                        Invite friends to move up in line. For each referral, you’ll move up <b>100</b> spots!
                    </div>
                    <div className="social">
                        {data.map((val, key) => {
                            return (
                                <div className="social-container" key={key} >
                                    <a href={val.link} target="_blank">
                                        <img alt="" src={val.image} />
                                    </a>

                                </div>
                            )
                        })}
                    </div>
                    <div className="copy-link">Or copy link</div>
                    <div className="link-container">
                        <div className="link">{url}</div>
                        <div className="copy-btn" onClick={() => {
                            navigator.clipboard.writeText(url)
                            setCopied(true)

                        }} >{copied ? "Copied" : "Copy"}</div>
                    </div>
                    <a href="https://www.producthunt.com/posts/zapmail?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-zapmail" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=400525&theme=light" alt="Zapmail - Slack&#0032;as&#0032;an&#0032;email&#0032;service | Product Hunt" className="product-hunt" /></a>
                    <div className="go-btn" onClick={() => {
                        window.location.href = `https://zapmail.app`
                    }}>Go to Zapmail</div>

                </div>

            </div>
        </div>
    )
}
export default SignupSuccess