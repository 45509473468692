import React, { useEffect } from "react";
import "./CarouselStyles.scss"
import slide1 from "../../Assets/Icons/Icon-1.gif"
import slide2 from "../../Assets/Icons/Icon-2.gif"
import slide3 from "../../Assets/Icons/Icon-3.gif"
import slide4 from "../../Assets/Icons/Icon-4.gif"
import slide5 from "../../Assets/Icons/Icon-5.gif"
import slide6 from "../../Assets/Icons/Icon-6.gif"


const Carousel = () => {
    let slideIndex = 1;
    useEffect(() => {
        showSlides(slideIndex);
        setInterval(() => {
            plusSlides(1)
        }, 5000);
    }, []);

    const plusSlides = (n) => {
        showSlides(slideIndex += n);
    }

    const currentSlide = (n) => {
        showSlides(slideIndex = n);
    }


    const showSlides = (n) => {
        let i;
        let slides = document.getElementsByClassName("mySlides");
        let dots = document.getElementsByClassName("dotted");
        if (slides.length > 0) {
            // console.log(slides);
            if (n > slides.length) { slideIndex = 1 }
            if (n < 1) { slideIndex = slides.length }
            for (i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            for (i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className?.replace(" active", "");
            }
            slides[slideIndex - 1].style.display = "block";
            dots[slideIndex - 1].className += " active";
        }



    }
    const Data = [
        {
            image: slide1,
            header: "External Integration",
            sub: "Provides cross platform email communication with other services"
        }, {
            image: slide2,
            header: "Conversational Mailing",
            sub: "Gives a common ground between professional and conversational styles"
        }, {
            image: slide3,
            header: "Grouping",
            sub: "Create chat groups with any number of members and moderators"
        }, {
            image: slide4,
            header: "Post Message Tagging",
            sub: "Users can be tagged in distinct messages within a private chat"
        }, {
            image: slide5,
            header: "Instant Voice Mails",
            sub: "Provides cross platform email communication with other services"
        }, {
            image: slide6,
            header: "Add As Task",
            sub: "Turn a message into schedules tast with a single click"
        }
    ]
    return (
        <div className="slideshow-container">
            {Data.map((data, i) => {
                return (<div className="mySlides fade" key={i}>
                    <img src={data.image} style={{ width: "100%" }} />
                    <div className="numbertext">{data.header}</div>
                    <div className="text">{data.sub}</div>
                </div>
                )
            })}

            <div style={{ textAlign: "center" }} className='dot-bar'>
                <span className="dotted" onClick={() => currentSlide(1)}></span>
                <span className="dotted" onClick={() => currentSlide(2)}></span>
                <span className="dotted" onClick={() => currentSlide(3)}></span>
                <span className="dotted" onClick={() => currentSlide(4)}></span>
                <span className="dotted" onClick={() => currentSlide(5)}></span>
                <span className="dotted" onClick={() => currentSlide(6)}></span>
            </div>


        </div>
    )
}
export default Carousel