import React, { useState } from "react";
import "./InputContainerStyles.scss";
import userIcon from "../../../../Assets/Icons/show.png";
import pwdIcon2 from "../../../../Assets/Icons/unshow.png";
import Done from "../../../../Assets/Icons/done.png";
import SignupButtonContainer from "../../../../Components/Button/SignupButtonContainer/SignupButtonContainer";
import { useSignUpFlowContext } from "../../../Auth/SignUpFlow/GlobalStates";
import { sendOTP } from "../../SignUpFlow/API_reqs";

const InputContainer = (props) => {
  const [isFocus, setIsFocus] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [otpSending, setOtpSending] = useState(false);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const {
    email,
    setEmail,
    setemailError,
    otpSent,
    setOtp,
    setOtpSent,
    otpDone,
    sideButtonText,
    setSideButtonText,
    setOtpEmail,
    setemailErrorText,
    setMinutes,
    setSeconds,
    selectDomain,
    setTimeOut,
    setOtpDone
  } = useSignUpFlowContext();

  const sideButtonClick = () => {
    // console.log("side button clicked", email, otpSent, timer);
    if (otpSent && sideButtonText === "Change Email") {
      // console.log(1);
      setOtpSent(false);
      setSideButtonText("Send OTP");
      setemailError(false);
      setemailErrorText(
        "This will be used to contact you during the setup. Please provice a valid email"
      );
      setOtpEmail("");
      setOtp("");
      setEmail("");
      setMinutes(Math.floor(0 / 60));
      setSeconds(0 % 60);
      setOtpDone(false);
    } else {
      if (email !== "") {
        if (emailRegex.test(email)) {
          setSideButtonText("Sending OTP");
          setOtpSending(true);
          sendOTP({
            email: email,
          })
            .then(async (response) => {
              setOtpSending(false);
              if (response.status === 201) {
                setemailError(false);
                setMinutes(Math.floor(90 / 60));
                setSeconds(90 % 60);
                setOtpEmail(email);
                setOtpSent(true);
                setSideButtonText("Change Email");
                setTimeOut(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setemailError(true);
              setemailErrorText("OTP Sending error. Please try again.");
              setSideButtonText("Send OTP");
            });
        } else {
          // console.log("not ok");
          setemailError(true);
          setemailErrorText("Please provide a valid email");
        }
      } else {
        // setEmailSubText("This will be used to contact you during the setup. Please provice a valid email")
      }
    }
  };

  return (
    <div style={{'width':'100%'}}>
      <div
        className={`InputContainer 
                ${
                  props.type === "username" ||
                  props.type === "password" ||
                  props.type === "confirmPass"
                    ? "InputUsername"
                    : ""
                }`}
        style={{
          borderColor: props.success
            ? "#51B62D"
            : props.error
            ? "#D85757"
            : props.signUp
            ? "#4E4E4E"
            : isFocus
            ? "#DADADA"
            : props.disabled
            ? "#353535"
            : "",
          height: props.height ? props.height : "",
          marginTop: props.marginTop ? props.marginTop : "",
          display: props.display ? props.display : "",
          alignItems: "center",
        }}
      >
        <input
          style={{
            color: props.color ? props.color : props.disabled ? "#353535" : "",
            fontSize: props.fontSize ? props.fontSize : "",
            fontFamily: props.signUp ? "Poppins, sans-serif" : "",
          }}
          className="zp-input-container"
          placeholder={props.placeholder}
          value={props.value}
          type={
            (props.type === "password" || props.type === "confirmPass") &&
            !showPwd
              ? "password"
              : props.type === "number"
              ? "number"
              : props.type === "email"
              ? "email"
              : "text"
          }
          onFocus={() => {
            setIsFocus(true);
            if (props.onFocus) {
              props.onFocus();
            }
          }}
          disabled={props.disabled}
          onBlur={() => {
            setIsFocus(false);
            if (props.onBlur) {
              props.onBlur();
            }
          }}
          onChange={(e) => {
            props.onChange(e);
          }}
        />
        <div
          className="icon-container"
          style={{ width: props.signUpFlow ? "30%" : "" }}
        >
          {props.type === "side-button" && email !== "" ? (
            <SignupButtonContainer
              title={sideButtonText}
              onClick={props.signUpFlow ? sideButtonClick : null}
              primary
              isLoading={otpSending}
              // disabled = { props.signUpFlow && otpSent ? true : false}
            />
          ) : (
            ""
          )}

          {props.type === "username" ? (
            <span for="fname" className="domain-name2">
              @{selectDomain}
            </span>
          ) : (
            ""
          )}

          {props.type === "password" ? (
            <span
              for="fname"
              className="icon"
              onClick={() => {
                if (showPwd) {
                  setShowPwd(false);
                } else {
                  setShowPwd(true);
                }
              }}
            >
              {showPwd ? (
                <img src={pwdIcon2} alt="" />
              ) : (
                <img src={userIcon} alt="" />
              )}
            </span>
          ) : (
            ""
          )}

          {props.type === "confirmPass" ? (
            <span
              for="fname"
              className="icon"
              onClick={() => {
                if (showPwd) {
                  setShowPwd(false);
                } else {
                  setShowPwd(true);
                }
              }}
            >
              {showPwd ? (
                <img src={pwdIcon2} alt="" />
              ) : (
                <img src={userIcon} alt="" />
              )}
            </span>
          ) : (
            ""
          )}

          {props.otp && otpDone ? (
            <span
              className="icon"
              style={{
                width: props.otp ? "2.063rem" : "",
                height: props.otp ? "2.063rem" : "",
              }}
            >
              <img src={Done} alt="" />
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
      {props.error ? (
        <div className="required-text">{props.errorText}</div>
      ) : (
        ""
      )}
      {props.success ? (
        <div className="success-text">{props.successText}</div>
      ) : (
        ""
      )}
    </div>
  );
};
export default InputContainer;
