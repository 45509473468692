import React, { useEffect } from "react";
import "../../SignUpFlow.scss";
import InputContainer from "../../../Signup/InputContainer/InputContainer";
import { useSignUpFlowContext } from "../../../SignUpFlow/GlobalStates";

const EnterDomain = () => {
  const {
    domain,
    setDomain,
    setDomainError,
    domainError,
    domainErrorText,
    setLoader,
    pageWidth,
    footerHeight,
    mode
  } = useSignUpFlowContext();

  useEffect(() => {
    setLoader(80);
  });

  return (
    <div
      className="business-details-wrapper"
      style={{
        paddingBottom: pageWidth > 900 && `${footerHeight}px`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2 className="your-details-header">Domain</h2>
        <div
          style={{
            margin: "0 0 1.68rem",
            color: mode === "darkmode" ? "white" : "black",
            fontStyle: "italic",
            fontWeight: "200",
            fontSize: "1.125rem",
          }}
        >
          <p>
            Enter your business's domain name. You’ll use it to set up custom
            email addresses, like info@example.com. We'll walk you through
            verifying that your business owns this domain later.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <InputContainer
            placeholder="Your domain name"
            marginTop="0"
            signUp
            color={mode === "darkmode" ? "white" : "#000"}
            value={domain}
            onChange={(e) => {
              setDomain(e.target.value.toLowerCase());
              setDomainError(false);
            }}
            error={domainError}
            errorText={domainErrorText}
          />
        </div>
      </div>
    </div>
  );
};
export default EnterDomain;
