import React, { useState } from "react";
import "./SignupWrapperStyles.scss";
import logo from "../../../../Assets/Images/logo-new.png";
import InputContainer from "../InputContainer/InputContainer";
import SignupButtonContainer from "../SignupButtonContainer/SignupButtonContainer";
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment";



const SignupWrapper = (props) => {
    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)
    const [dateError, setDateError] = useState(false)
    const [monthError, setMonthError] = useState(false)
    const [yearError, setYearError] = useState(false)
    const [ageError, setAgeError] = useState(false)
    const [firstNameErrorText,setFirstNameErrorText]=useState("")
    const [lastNameErrorText,setLastNameErrorText]=useState("")

    const onNextClick = () => {

        let dateX = moment(`${props.year}-${props.month}-${props.date}`, 'YYYY-MMM-DD');
        let dob = dateX.format('YYYY-DD-MMMM');
        let year16plus = moment().subtract(16, "years").format("YYYY-DD-MMMM");

        if (props.firstName === "") {
            setFirstNameErrorText("* Required")
            setFirstNameError(true)
        } if (props.lastName === "") {
            setLastNameErrorText("* Required")
            setLastNameError(true)
        }
        if (props.date === "") {
            setDateError(true)
        } if (props.month === "") {
            setMonthError(true)
        } if (props.year === "") {
            setYearError(true)
        }
        if (dob >= year16plus) {
            setAgeError(true)
        }
        if(props.firstName.length<4){
            setFirstNameErrorText("Your First Name should contain atleast 4 characters")
            setFirstNameError(true)
        }
        if(props.lastName.length<4){
            setLastNameErrorText("Your Last Name should contain atleast 4 characters")
            setLastNameError(true)
        }
        if (props.firstName !== "" && 
        props.lastName !== "" && 
        props.date !== "" && 
        props.month !== "" && 
        props.year !== "" &&
         dob <= year16plus &&
         props.firstName.length>=4&&
         props.lastName.length>=4) {
            props.setSelectedPage(2)
        }


    }



    return (
        <div className="SignupWrapper">
            <div className="SignupWrapper-container">
                <div className="header-container">
                    <div className="logo-container">
                    <div className="back-icon"/>
                        <img alt="" src={logo} className="logo" />
                    </div>
                    <div className="signup-header">
                        Create a Zapmail Account
                    </div>
                    <div className="signup-subheader">
                        Enter your basic details
                    </div>
                    <div>
                        <InputContainer placeholder="First Name" value={props.firstName} type="firstName" onChange={e => {
                            props.setFirstName(e.target.value)
                            setFirstNameError(false)

                        }} error={firstNameError}  errorText={firstNameErrorText} />
                        <InputContainer placeholder="Last Name" value={props.lastName} type="lastName" onChange={e => {
                            props.setLastName(e.target.value)
                            setLastNameError(false)

                        }} error={lastNameError}errorText={lastNameErrorText} />
                        <DatePicker date={props.date} setDate={props.setDate} month={props.month} setMonth={props.setMonth} year={props.year} setYear={props.setYear}
                            dateError={dateError || ageError}
                            setDateError={setDateError}
                            monthError={monthError || ageError}
                            setMonthError={setMonthError}
                            yearError={yearError || ageError}
                            setYearError={setYearError}
                            setAgeError={setAgeError}

                        />
                        {dateError || monthError || yearError ?
                            <div className="required-text">* Required</div> : ""}
                        {ageError ?
                            <div className="required-text">* Hey, we are sorry! We are still not able to sign up anyone under 16.</div> : ""}



                    </div>
                    <div className="btn-container-right">
                        <SignupButtonContainer title="Next" primary onClick={onNextClick} />
                    </div>

                </div>

            </div>
        </div>
    )
}
export default SignupWrapper
