import React, { useEffect } from "react";
import logo from "../../../../../Assets/Images/logo.png";
import { useSignUpFlowContext } from "../../GlobalStates";

const SignupNavbar = () => {
    const { setHeaderHeight } = useSignUpFlowContext();
    useEffect(() => {
        setHeaderHeight(document.getElementById("header").clientHeight);
    });
    return (
        <div className="header-container" id="header">
            <img alt="Zapmail" src={logo} className="image" />
        </div>
    )
}
export default SignupNavbar