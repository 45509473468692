import React, { useEffect, useContext } from "react";
import "./DomainWrapperStyles.scss";
import back from "../../../../Assets/Icons/back.png";
import logo from "../../../../Assets/Images/logo-new.png";
import blackArrow from "../../../../Assets/Icons/domain-arrow.png";
import whiteArrow from "../../../../Assets/Icons/domain-arrow-white.png";
import no1 from "../../../../Assets/Icons/no1.png";

import SignupButtonContainer from "../SignupButtonContainer/SignupButtonContainer";
import AppContext from "../../../../ContextApi/AppContext";

const DomainWrapper = (props) => {
    const newContext = useContext(AppContext)
    useEffect(() => {
        newContext.getPublicDomains()
    }, [])
    const getHeight = () => {
        if (document.getElementById("header-conatiner-id")) {
            let h = document.getElementById("header-conatiner-id").clientHeight + document.getElementById("button-container-id").clientHeight + 176;
            return `calc(100vh - ${h}px)`;
        }
    };
    const getDomainClicked = (domain) => {
        props.setDomain(domain)
        props.setSelectedPage(4)
    }
    return (
        <AppContext.Consumer>
            {(context) => (
                <div className="DomainWrapper">
                    <div className="DomainWrapper-container">
                        <div className="header-conatiner" id="header-conatiner-id">
                            <div className="logo-container">
                                <img alt="" src={back} className="back-icon" onClick={() => {
                                    props.setSelectedPage(2)
                                }} />
                                <img alt="" src={logo} className="logo" />
                            </div>
                            <div className="header-domain">
                                Top email domains available <div className="bold">exclusive on zapmail</div>
                            </div>
                        </div>
                        {context.isPublicDomainLoading ? <div className="domain-loader" /> :
                            <div className="Domain-list-container" style={{ maxHeight: getHeight() }}>
                                {context.publicDomains?.map((domain, key) => {
                                    return (
                                        <div className="domain-details" key={key}>
                                            <div className="detail-container">
                                                <div className="domain-dp" style={{ border: key === 0 ? "none" : "" }}>
                                                    {key === 0 ?
                                                        <div className="fire-conatiner">
                                                            <img alt="" src={no1} className="fire-icon" />
                                                            <div className="first-content">
                                                                <div>
                                                                    <img alt="" className="arrow" src={whiteArrow} />
                                                                </div>
                                                                {domain.user_count}</div>
                                                        </div> :
                                                        <div className="content">
                                                            <div>
                                                                <img alt="" className="arrow" src={blackArrow} />
                                                            </div>
                                                            {domain.user_count}</div>}
                                                </div>
                                                <div className="domain-name">
                                                    <div className="name">
                                                        {domain.domain_name}
                                                    </div>
                                                    <div className="powered">
                                                        Powered
                                                        <span className="span-style">
                                                            zapmail.app
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>

                                            <SignupButtonContainer title="Get for free" primary onClick={() => { getDomainClicked(domain.domain_name) }} />
                                        </div>
                                    )

                                })}

                            </div>}
                        <div className="button-container" id="button-container-id">
                            {/* <SignupButtonContainer title="Get your name domain" /> */}
                            <a href={`https://www.namecheap.com/?gclid=CjwKCAjwxOymBhAFEiwAnodBLPLJuKgfUeqDFbh5PRNK1x6GgkJ_KM8Qj5cAdu1eJYXapEYhV9r-zhoCSEIQAvD_BwE`} target="_blank">
                                <SignupButtonContainer title="Buy A Domain" />

                            </a>
                        </div>
                    </div>

                </div>
            )}
        </AppContext.Consumer>

    )

}
export default DomainWrapper