import React, { forwardRef } from "react";
import "./ButtonStyles.scss";
import AppContext from "../../ContextApi/AppContext";

const Button = forwardRef((props, ref) => {
  return (
    <AppContext.Consumer>
      {(context) => (
        <div
          ref={ref}
          className={`${props.back ? `back-button-${context.mode}` : `submit-button-${context.mode}`} ${props.loading ? " is-loading" : ""}`}
          disabled={props.disabled}
          onClick={props.onClick}
          style={{
            cursor: props.notAllowed ? "not-allowed" : "pointer",
          }}
        >
          {!props.loading ? props.buttonText : <div className="loader" />}
        </div>)}</AppContext.Consumer>
  );
});
export default Button;
