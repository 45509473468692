import axios from "axios";
import config from "../../config";
export const restHelper = () => {
  const callAPI = async (endpointurl, options = {}) => {
    const defaultMethod = "GET";
    const defaultHeaders = {
      "Access-Control-Allow-Origin": "*",
    };
    const baseURL = `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`;
    options.method = options.method ? options.method : defaultMethod;
    options.headers = options.headers
      ? { ...defaultHeaders, ...options.headers }
      : defaultHeaders;
    options.data = options.body ? options.body : false;
    options.url = endpointurl;
    options.baseURL = baseURL;
    if (!options.data) delete options.data;
    try {
      const apiResponse = await axios(options);
      return apiResponse;
    } catch (err) {
      return err;
    }
  };
  //calling get API For fetching data
  const get = (endpointurl, options = {}) => {
    callAPI(endpointurl, options);
  };

  //Post to insert
  const postCreate = (endpointurl, options) => {
    options.method = "POST";
    return callAPI(endpointurl, options);
  };

  //Put Api calling
  const putUpdate = (endpointurl, options) => {
    options.method = "PUT";
    return callAPI(endpointurl, options);
  };

  //Delete Api calling
  const deletedata = (endpointurl, options) => {
    options.method = "DELETE";
    return callAPI(endpointurl, options);
  };
  return {
    get,
    postCreate,
    putUpdate,
    deletedata,
  };
};
