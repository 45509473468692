import React from "react";
import "./SignupButtonContainerStyles.scss"
import AppContext from "../../../ContextApi/AppContext";

const SignupButtonContainer = (props) => {

    return (
        <AppContext.Consumer>
            {(context) => (
                <div className={`SignupButtonContainer ${props.primary ? "ButtonContainer-Primary" : props.danger ? "ButtonContainer-Danger" : props.cancel ? "ButtonContainer-Cancel" : props.line ? `ButtonContainer-Line-${context.mode}` : props.disabled ? "ButtonContainer-Disabled" : props.grey ? `ButtonContainer-Grey-${context.mode}` : "ButtonContainer-Secondary"}`}
                    onClick={props.onClick}
                    style={{ width: props.width ? props.width : "", fontSize: props.fontSize ? props.fontSize : "", margin: props.margin ? props.margin : "" }}
                >
                    {props.isLoading ? <div className="sign-loader" />
                        : props.title}
                </div>
            )}
        </AppContext.Consumer>
    )

}
export default SignupButtonContainer