import React, { useState, useEffect, useRef } from "react";
import AppContext from "../../../ContextApi/AppContext";
import { useHistory, useLocation } from "react-router-dom";
import "./ForgotPassword.scss";
import UsernameInput from "../../../Components/UsernameInput/UsernameInput";
import Button from "../../../Components/Button/Button";
import logo from "../../../Assets/Images/logo.png";

const OtpForgotPassword = () => {
    const [otp, setOtp] = useState("");
    const forgetBtn = useRef(null);
    const [otpError, setOtpError] = useState(false);
    const [isNewPasswordFocus, setIsNewPasswordFocus] = useState(false);
    const [otpErrorText, setOtpErrorText] = useState("*Required");
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        if (location.state === undefined) {
            history.push({
                pathname: "/",
            });
        }
    }, [])

    return (
        <AppContext.Consumer>
            {(context) => (
                <div className={`forget-${context.mode}`}>
                    <div className="_forgetContainer">
                        <div className="form-wrapper">
                            <div className="header-container">
                                <img alt="" src={logo} className="image" />
                            </div>
                            <div className="forgetwrapper">
                                <div className="header">Forgot Password</div>
                                <div className="margin" />
                                <div className="subtext">An email with a verification code was just sent to {location.state && location.state.recovery_email}</div>
                                <div className="input-wrapper">
                                    <UsernameInput
                                        type={"password"}
                                        placeholder={"OTP"}
                                        onChange={(e) => {
                                            setOtpError(false)
                                            setOtp(e.target.value)
                                            if (e.target.value.length === 6) {
                                                let email = location.state && location.state.email
                                                context.verifyResetOTP(email, e.target.value).then((data) => {
                                                    if (data) {
                                                        history.push({
                                                            pathname: "/forget-password/2",
                                                            state: {
                                                                email: email,
                                                                x_access_token: data.x_access_token
                                                            }
                                                        })
                                                    }
                                                }).catch((error) => {
                                                    setOtpErrorText("Invalid OTP. Please try again.");

                                                    setOtpError(true);
                                                });
                                            }
                                        }}
                                        isPwdFocus={isNewPasswordFocus}
                                        pwdError={otpError}
                                        onBlur={() => {
                                        }}
                                        onFocus={() => {
                                        }}
                                        value={otp}
                                        maxLength={6}

                                    />
                                    {otpError ? (
                                        <div className="form-fail">
                                            {otpErrorText}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="margin" />
                                <Button
                                    ref={forgetBtn}
                                    loading={context?.forgetIsLoading}
                                    buttonText="Submit"
                                    onClick={() => {
                                        if (otp !== "" && otp.length === 6) {

                                            let email = location.state && location.state.email
                                            context.verifyResetOTP(email, otp).then((data) => {
                                                if (data) {
                                                    history.push({
                                                        pathname: "/forget-password/2",
                                                        state: {
                                                            email: email,
                                                            x_access_token: data.x_access_token
                                                        }
                                                    })
                                                }

                                            }).catch((error) => {
                                                if (error.response.status === 401) {
                                                    setOtpErrorText("Invalid OTP. Please try again.");
                                                } else if (error.response.status === 403) {
                                                    setOtpErrorText(
                                                        "OTP Expired. Please request another OTP"
                                                    );
                                                }
                                                setOtpError(true);
                                            });
                                        } else if (otp === "" || otp.length < 6) {
                                            setOtpErrorText(
                                                "*Required"
                                            );
                                            setOtpError(true)
                                        }
                                    }}
                                />
                                <div className="margin-btn" />
                                <Button
                                    back
                                    ref={forgetBtn}
                                    buttonText="Back"
                                    onClick={() => {
                                        history.push({
                                            pathname: "/",
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </AppContext.Consumer>
    );
};
export default OtpForgotPassword;
