import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Gleap from "gleap";
import config from "./config"
import { SignUpFlowContextProvider } from "./pages/Auth/SignUpFlow/GlobalStates";

Sentry.init({
  release: process.env.REACT_APP_RELEASE_VERSION,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

Gleap.initialize(config.gleap);
ReactDOM.render(
  <React.StrictMode>
    <SignUpFlowContextProvider>
    <App />
    </SignUpFlowContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
