import "./App.css";
import "bulma/css/bulma.min.css";
import Routes from "./Routes";
import "./fonts/HelveticaNeue Light.ttf";
import "./fonts/HelveticaNeue Medium.ttf";
import "./fonts/HelveticaNeue Light.ttf";

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
