import React, { useEffect, useState } from "react";
import InputContainer from "../Signup/InputContainer/InputContainer";
import SignupButtonContainer from "../Signup/SignupButtonContainer/SignupButtonContainer";
import "./index.scss";
import "../SignUpFlow/SignUpFlow.scss";
import { useSignUpFlowContext } from "../SignUpFlow/GlobalStates";
import { useHistory, useParams } from "react-router-dom";
import {
  Payhere,
  AccountCategory,
  PayhereSubscription,
  SubscriptionParams,
  Customer,
  Month,
  CurrencyType,
} from "@payhere-js-sdk/client";
import axios from "axios";
import SignupNavbar from "../SignUpFlow/Components/SignupNavbar/SignupNavbar";
import check from "../../../Assets/Icons/check.png";
import config from "../../../config";
import { prePaymentUserInfo } from "../SignUpFlow/API_reqs";

const AddAccount = (props) => {
  useEffect(() => {});
  const { selectedItem, firstName, secondName, numberOfEmp, email, number, businessName, jobTitle, emailPrice,mode } =
    useSignUpFlowContext();

  const history = useHistory();
  const params = useParams();

  process.env.REACT_APP_ENV === "prod"
    ? Payhere.init("224315", AccountCategory.LIVE)
    : Payhere.init("1224576", AccountCategory.SANDBOX);

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [agree, setAgree] = useState(false);
  const [agreeError, setAgreeError] = useState(false);
  const [invoice, setInvoice] = useState("");
  const [amount, setAmount] = useState("");
  const [item, setItem] = useState("");
  const [hash, setHash] = useState("");
  const [redirect, setRedirect] = useState("/signup-flow-details");

  function onPayhereSubscriptionError(errorMsg) {
    alert(errorMsg);
  }

  const getInvoice = () => {
    console.log("amount: ", amount);

    let data ={
      amount: amount,
      currency: currency,
      items: item,
      first_name:firstName,
      last_name:secondName,
      business_name: businessName,
      job_title: jobTitle,
      accounts: numberOfEmp.toString(),
      phone_number: number,
      email:email
    }
    if (amount != "") {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "post",
        // baseURL: "http://localhost:4000",
        baseURL: config.apiEndpoints.protocol + config.apiEndpoints.authServer,
        url: "/invoice/create",
        data: data,
      })
        .then((response) => {
          setInvoice(response.data.invoice_id);
          setTimeout(() => {
            getHash(response.data.invoice_id);
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getHash = (invoice_id) => {
    let requestParam =
      "?amount=" + amount + "&currency=" + currency + "&invoice=" + invoice_id;
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      // baseURL: "http://localhost:4000",
      baseURL: config.apiEndpoints.protocol + config.apiEndpoints.authServer,
      url: "/payhere/hash" + requestParam,
    })
      .then((response) => {
        setHash(response.data.payhere_hash);
      })
      .catch((error) => {
        // reject(error);
      });
  };

  const handlePayhere = () => {
    try {
      const customer = new Customer({
        first_name: firstName,
        last_name: secondName,
        phone_number: number,
        email: email,
        address: address,
        city: city,
        country: country,
      });

      const subscriptionData = new SubscriptionParams({
        returnUrl: config.domain.auth + '/payment/success',
        cancelUrl: 'https://getzapmail.com',
        notifyUrl:
          config.apiEndpoints.protocol +
          config.apiEndpoints.authServer +
          "/payhere/notify",
        order_id: invoice,
        itemTitle: item,
        recurrence: new Month(1),
        duration: "Forever",
        currency: currency == "USD" ? CurrencyType.USD : CurrencyType.LKR,
        amount: amount,
        hash: hash,
      });
      const subscription = new PayhereSubscription(
        customer,
        subscriptionData,
        onPayhereSubscriptionError
      );
      subscription.start();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    switch (selectedItem) {
      case 0:
        if(numberOfEmp > 0){
          setAmount((emailPrice * numberOfEmp).toString() + ".00");
          setItem(numberOfEmp + " Business Mail");
        }
        break;
      case 1:
        if(numberOfEmp > 0){
          setAmount(((emailPrice * numberOfEmp) + 10).toString() + ".00")
          setItem(numberOfEmp + " Business Mail & AI Website");
        }
        else{
          setAmount("10.00")
          setItem("AI Website");
        }
        break;
      case 2:
        if(numberOfEmp > 0){
          setAmount(((emailPrice * numberOfEmp) + 5).toString() + ".00")
          setItem(numberOfEmp + " Business Mail & Bulk Email");
        }
        else{
          setAmount("5.00")
          setItem("Bulk Email");
        }
        break;
      case 3:
        if(numberOfEmp > 0){
          setAmount(((emailPrice * numberOfEmp) + 15).toString() + ".00")
          setItem(numberOfEmp + " Business Mail, AI Website & Bulk Email");
        }
        break;
      case 4:
        setCurrency("LKR");
        setAmount("6299.00")
        setItem("10 Business Mail, AI Website & Email Marketing");
        break;
      case 5:
        setCurrency("LKR");
        setAmount("3449.00");
        setItem("3 Business Mail & AI Website");
        break;
      case 6:
        setCurrency("LKR");
        setAmount("9849.00")
        setItem("30 Business Mail, AI Website, Bulk Email & 500 Zappy Credits");
        break;
      case 7:
        setAmount("11.95");
        setItem("3 Business Mail & AI Website");
        break;
      case 8:
        setAmount("19.95")
        setItem("10 Business Mail, AI Website & Email Marketing");
        break;
      case 9:
        setAmount("29.95");
        setItem("30 Business Mail, AI Website, Bulk Email & 500 Zappy Credits");
        break;
      case 10:
        setAmount("20.00")
        setItem("AI Website");
        break;
      case 11:
        setAmount("10.00")
        setItem("Bulk Email");
        break;
      default:
        // setAmount((1 * numberOfEmp).toString() + ".00");
        break;
    }
  }, []);

  useEffect(() => {
    getInvoice();
  }, [item]);

  return (
    <>
      <div className={`SignupFlow-${mode}`}>
        <header className="header-container">
          <SignupNavbar />
        </header>
      </div>
      <div className={`main-body-pay-${mode}`}>
        <center className="inputs-wrapper">
          <h2>Submit your details</h2>

          <InputContainer
                  color={mode === "darkmode" ? "white" : "#000"}
            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="Address"
            value={address}
            type="text"
            display="flex"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            error={false}
            errorText={"otpErrorText"}
          />

          <InputContainer
                 color={mode === "darkmode" ? "white" : "#000"}

            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="City"
            value={city}
            type="text"
            display="flex"
            onChange={(e) => {
              setCity(e.target.value);
            }}
            error={false}
            errorText={"otpErrorText"}
          />

          <InputContainer
                color={mode === "darkmode" ? "white" : "#000"}

            height="4.18rem"
            fontSize="1rem"
            marginTop="1.088rem"
            placeholder="Country"
            value={country}
            type="text"
            display="flex"
            onChange={(e) => {
              setCountry(e.target.value);
            }}
            error={false}
            errorText={"otpErrorText"}
          />
          <div style={{ display: "flex" }}>
            <div
              className={`check-container ${agreeError ? "check-error" : ""}`}
              onClick={() => {
                setAgree(!agree);
                setAgreeError(false);
              }}
            >
              <div
                className={`check-box ${
                  agreeError ? "checked-error" : agree ? "checked" : ""
                }`}
              >
                {agree && <img alt="" src={check} className="check" />}
              </div>
              I agree to the{" "}
              <a
                href="https://getzapmail.com/legal/"
                style={{ marginLeft: "5px" }}
                target="_blank"
              >
                Terms & Conditions
              </a>
            </div>
          </div>
          <div className="pay-verify">
            <SignupButtonContainer
              title="Confirm & Pay"
              line
              onClick={() => {
                if (!agree) {
                  setAgreeError(true);
                  return;
                }
                handlePayhere();
              }}
            />
          </div>
        </center>
      </div>
    </>
  );
};

export default AddAccount;
