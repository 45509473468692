import React, { useEffect } from "react";
import "../../SignUpFlow.scss";
import SignupButtonContainer from "../../../../../Components/Button/SignupButtonContainer/SignupButtonContainer";
import { useSignUpFlowContext } from "../../GlobalStates";

const HaveDomain = () => {
  const {
    setSelectedPage,
    setLoader,
    footerHeight,
    pageWidth,
    setBusinessName,
    setNumberOfEmp,
    setJobTitle,
    setFirstName,
    setSecondName,
    setOtpEmail,
    setNumber,
    mode
  } = useSignUpFlowContext();
  useEffect(() => {
    setLoader(50);
    if (localStorage.getItem("businessName")) {
      setBusinessName(localStorage.getItem("businessName"));
      setNumberOfEmp(localStorage.getItem("numberOfEmp"));
      setJobTitle(localStorage.getItem("jobTitle"));
      setFirstName(localStorage.getItem("firstName"));
      setSecondName(localStorage.getItem("secondName"));
      setOtpEmail(localStorage.getItem("email"));
      setNumber(localStorage.getItem("number"));
    }
  }, []);
  return (
    <div
      className="business-details-wrapper"
      style={{
        paddingBottom: pageWidth > 900 && `${footerHeight}px`,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h2 className="your-details-header">
          Does your business have a domain?
        </h2>
        <div
          style={{
            margin: "0 0 1.68rem",
            color: mode === "darkmode" ? "white" : "black",
            fontStyle: "italic",
            fontWeight: "200",
          }}
        >
          <p style={{ fontSize: '1rem' }}>
            You'll need a domain, like example.com, to set up email and zapmail
            accounts for your business.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <SignupButtonContainer
            width="15.875rem"
            title="I have a domain"
            grey
            onClick={() => {
              setSelectedPage(4);
            }}
          />
          <SignupButtonContainer
            width="15.875rem"
            title="No, I need a domain"
            grey
            margin="0 0 0 0.625rem"
            onClick={() => {
              setSelectedPage(5);
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default HaveDomain;
