import React, { useEffect, useState } from "react";
import "./index.scss";
import { useSignUpFlowContext } from "../SignUpFlow/GlobalStates";
import SignupNavbar from "../SignUpFlow/Components/SignupNavbar/SignupNavbar";
import SignupButtonContainer from "../Signup/SignupButtonContainer/SignupButtonContainer";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import config from "../../../config";

const PaymentSuccess = (props) => {
  useEffect(() => {
  }); 
  const { setSelectedPage, setBusinessName, setNumberOfEmp, setJobTitle, setAgree, setFirstName, setSecondName, setOtpEmail, setNumber,mode } =
  useSignUpFlowContext();

  const location = useLocation();
  const history = useHistory();
  const [order, setOrder] = useState({items:""});
  const [showNavigation, setShowNavigation] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleInvoice = () => {
    let itemSplit = order.items.split(" ")
    if(order.status){
      if(itemSplit[1] === "Business" && itemSplit[2] === "Mail"){
        setBusinessName(order.business_name)
        setNumberOfEmp(order.no_accounts)
        setJobTitle(order.job_title)
        setFirstName(order.first_name)
        setSecondName(order.last_name)
        setOtpEmail(order.email)
        setAgree(true)
        setNumber(order.phone)
        setSelectedPage(3)
        setShowNavigation(true)
      }
      else{
        setTimeout(() => {
          window.location.href = 'https://getzapmail.com'
        }, 4000);
      }
    }
    else{
      setShowError(true)
    }
  }

  const handleNavigation = () => {
    history.push("/signup-flow-details");
  }

  useEffect(() => {
    let orderId = new URLSearchParams(location.search).get('order_id')
    localStorage.setItem("orderId", orderId)
    console.log(orderId)
    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "get",
      // baseURL: "http://localhost:4000",
      baseURL: config.apiEndpoints.protocol + config.apiEndpoints.authServer,
      url: "/invoice/" + orderId,
    })
      .then((response) => {
        console.log(response.data.invoice)
        setOrder(response.data.invoice);
      })
      .catch((error) => {
        // reject(error);
      });
  }, []);

  useEffect(() => {
    handleInvoice()
  }, [order]);

  return (
    <>
    <div className={`SignupFlow-${mode}`}>
      <header className="header-container">
        <SignupNavbar />
      </header>
    </div>
    <div className={`main-body-pay-${mode}`}>
      <center className="inputs-wrapper">
        <h2>Thank you we will send you an email shortly!</h2>
        {showError ? <h2>Awaiting payment confirmation!</h2> : ""}
        {showNavigation ?
          <div style={{'marginTop': '20px'}}>
            <SignupButtonContainer
              title="Continue Setup"
              line
              onClick={() => {
                handleNavigation();
              }}
            />
          </div>
          : ""}
      </center>
    </div>
    </>
  );
};

export default PaymentSuccess;
